<template>
    <div class="doc-prev_container"> 
        <div class="doc-prev_file-name"> {{ `${fileNameCutted}` }} <span>{{ ` (cтр. ${currentPage} из ${totalNumPages})` }}</span> </div>

        <div id="pdf-viewer">
            <div  
                v-if="isFileLoading" 
                class="document-preview_spinner-container"
            >
                <b-spinner
                    v-for="variant in spinerVariants"
                    class="document-preview_spinner"
                    :variant="variant"
                    :key="variant"
                    type="grow"
                ></b-spinner>
            </div>
            <b-button-toolbar class="right-tab_bts-container">
                    <b-button 
                        @click="onZoomIn"
                        :disabled="isError"
                        variant="outline-dark">
                        <b-icon 
                            icon="zoom-in" 
                            font-scale="1.5" 
                            aria-hidden="true"
                        ></b-icon>
                    </b-button>
                    <b-button 
                        @click="onZoomOut"
                        :disabled="isError"
                        variant="outline-dark">
                        <b-icon 
                            icon="zoom-out" 
                            font-scale="1.5" 
                            aria-hidden="true"
                        ></b-icon>
                    </b-button>
                    <b-button 
                        @click="onPrintFile"
                        :disabled="isError"
                        variant="outline-dark">
                        <b-icon 
                            icon="printer" 
                            font-scale="1.5" 
                            aria-hidden="true"
                        ></b-icon>
                    </b-button>
                    <b-button 
                        @click="onDownloadFile"
                        :disabled="isError || isFileDownloading"
                        variant="outline-dark">
                        <b-icon 
                            icon="download" 
                            font-scale="1.5" 
                            aria-hidden="true"
                        ></b-icon>
                    </b-button>
            </b-button-toolbar>

            <canvas 
                v-if="!isFileLoading"
                ref="pdfCanvas"
            ></canvas>

            <div class="bottom-tab">
                <b-button-toolbar class="bottom-tab_bts-container">
                    <b-button 
                        @click="onPrevClicked"
                        variant="outline-dark">
                        <b-icon 
                            icon="arrow-left" 
                            font-scale="1.5" 
                            aria-hidden="true"
                        ></b-icon>
                    </b-button>
                    <b-button 
                        @click="onNextClicked"
                        variant="outline-dark">
                        <b-icon 
                            icon="arrow-right" 
                            font-scale="1.5" 
                            aria-hidden="true"
                        ></b-icon>
                    </b-button>
                </b-button-toolbar>
            </div>
        </div>

        <iframe ref="pdfIframe" style="display: none;"></iframe>

    </div>
</template>


<script>
import * as pdfjsLib from 'pdfjs-dist/webpack';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import { BootstrapVueIcons } from 'bootstrap-vue';
import { Vue } from 'vue-property-decorator';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BootstrapVueIcons);

export default {
    name: 'document-preview-pdf',
    props: {
        fileName: null,
        fileNameCutted: null,
        spinerVariants: null,
        isFileLoading: {
            default: true,
        },
        isError: {
            default: false,
        },
        isFileDownloading: {
            default: false,
        }
    },
    mounted() {
    },
    watch: {
        currentPage() {
            this.renderPdfDocument();
        },
        scale() {
            this.renderPdfDocument();
        }
    },
    data() {
        return {
            doc: null,
            scale: 1,
            currentPage: 1,
            totalNumPages: 1,
            fileUploding: false,
            pageRendering: false,
            pageNumPending: null,
            pdfUrl: ''
        }
    },
    methods: {
        onPrevClicked() {
            if (this.currentPage > 1) {
                --this.currentPage;
            }
        },

        onNextClicked() {
            if (this.currentPage < this.totalNumPages) {
                ++this.currentPage;
            }
        },

        onZoomIn() {
            if (this.scale < 5) {
                this.scale += 0.2;
            }
        },

        onZoomOut() {
            if (this.scale > 1) {
                this.scale -= 0.2;
            }
        },

        onPrintFile() {
            const fileURL = this.pdfUrl;

            // Вставляем URL в iframe
            const iframe = this.$refs.pdfIframe;
            iframe.src = fileURL;

            // Ждем, пока iframe загрузится, затем вызываем печать
            iframe.onload = () => {
            iframe.contentWindow.print();
            };
        },

        onDownloadFile() {
            this.$emit('downloadFile', this.fileName)
        },

        async renderDocument(blob) {
            if (!blob) return;
            this.pdfUrl = URL.createObjectURL(blob);
            await this.getPdfDocument();
            this.setAllPages();
            this.renderPdfDocument();
        },
    
        //  ============= PDF ===================
        async getPdfDocument() {
            pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
            const loadingTask = pdfjsLib.getDocument(this.pdfUrl);
            this.doc = await loadingTask.promise;
        },

        setAllPages() {
            if (this.doc && this.doc.numPages) {
                this.totalNumPages = this.doc.numPages;
            }
        },

        async renderPdfDocument() {
            const page = await this.doc.getPage(this.currentPage);
            const canvas = this.$refs.pdfCanvas;
            const context = canvas.getContext('2d');

            const viewport = page.getViewport({scale: this.scale});
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };
            await page.render(renderContext).promise;
        },
    },
}

</script>

<style scoped>
.doc-prev_container {
    position: fixed;
    top: 56px;
    width: 100%;
    padding-right: 112px;
}

#pdf-viewer {
    text-align: center;
    padding: 20px;
    overflow-y: auto;
    height: calc(100vh - 106px);
}

.right-tab_bts-container {
    position: fixed;
    right: 100px;
    display: flex;
    flex-direction: column;
}

.right-tab_bts-container button{
    height: 50px;
    width: 50px;
    align-self: center;
    margin: 3px;
    opacity: 0.3;
}

.right-tab_bts-container button:hover {
    opacity: 1;
}

.doc-prev_file-name {
    padding: 5px 100px;
    width: 100%;
    text-align: center;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bottom-tab {
    position: fixed;
    bottom: 30px;
    width: calc(100% - 180px);
    text-align: center;
    opacity: 0.3;
}

.bottom-tab:hover {
    opacity: 1;
}

.bottom-tab_bts-container {
    display: flex;
    justify-content: center;
}

.bottom-tab_bts-container button{
    height: 30px;
    width: 70px;
    margin: 3px;    
}

.document-preview_spinner-container{
    margin-top: 10%;
    display: flex;
    justify-content: center;
}
.document-preview_spinner {
    margin: 10px;
}
</style>
