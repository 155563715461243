<template>
    <div class="doc-prev_container"> 
        <div class="doc-prev_file-name"> {{ `${fileNameCutted}` }}</div>
        
        <div id="picture-viewer" ref="pictureViewer">
            <div  
                v-if="isFileLoading" 
                class="document-preview_spinner-container"
            >
                <b-spinner
                    v-for="variant in spinerVariants"
                    class="document-preview_spinner"
                    :variant="variant"
                    :key="variant"
                    type="grow"
                ></b-spinner>
            </div>
            <b-button-toolbar class="right-tab_bts-container">
                    <b-button 
                        @click="onZoomIn"
                        :disabled="isError"
                        variant="outline-dark">
                        <b-icon 
                            icon="zoom-in" 
                            font-scale="1.5" 
                            aria-hidden="true"
                        ></b-icon>
                    </b-button>
                    <b-button 
                        @click="onZoomOut"
                        :disabled="isError"
                        variant="outline-dark">
                        <b-icon 
                            icon="zoom-out" 
                            font-scale="1.5" 
                            aria-hidden="true"
                        ></b-icon>
                    </b-button>
                    <b-button 
                        @click="onPrintFile"
                        :disabled="isError"
                        variant="outline-dark">
                        <b-icon 
                            icon="printer" 
                            font-scale="1.5" 
                            aria-hidden="true"
                        ></b-icon>
                    </b-button>
                    <b-button 
                        @click="onDownloadFile"
                        :disabled="isError || isFileDownloading"
                        variant="outline-dark">
                        <b-icon 
                            icon="download" 
                            font-scale="1.5" 
                            aria-hidden="true"
                        ></b-icon>
                    </b-button>
            </b-button-toolbar>

            <b-img
                v-if="!isFileLoading"
                :src="imageSrc" alt="fileName"
                :height="imageHeight"
                :width="imageWidth"
            ></b-img> 
        </div>

        <iframe ref="pictureIframe" style="display: none;"></iframe>

    </div>
</template>


<script>
import { BootstrapVueIcons } from 'bootstrap-vue';
import { Vue } from 'vue-property-decorator';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BootstrapVueIcons);

export default {
    name: 'document-preview-picture',
    props: {
        fileName: null,
        fileNameCutted: null,
        spinerVariants: null,
        isFileLoading: {
            default: true,
        },
        isError: {
            default: false,
        },
        isFileDownloading: {
            default: false,
        }
    },
    data() {
        return {
            binaryFile: null,
            imageSrc: '',
            imageHeight: null,
            imageWidth: null,
            imageHeightBase: null,
            imageWidthBase: null,
            scaleStep: 100,
            file: null
        }
    },
    methods: {
        onZoomIn() {
            if (this.imageHeight) {
                if (this.imageHeight < this.imageHeightBase * 2) {
                    this.imageHeight += this.scaleStep;
                }
            }
            if (this.imageWidth) {
                if (this.imageWidth < this.imageWidthBase * 2) {
                    this.imageWidth += this.scaleStep;
                }
            }
        },

        onZoomOut() {
            if (this.imageHeight) {
                if (this.imageHeight > this.imageHeightBase / 2) {
                    this.imageHeight -= this.scaleStep;
                }
            }
            if (this.imageWidth) {
                if (this.imageWidth > this.imageWidthBase / 2) {
                    this.imageWidth -= this.scaleStep;
                }
            }
        },

        onPrintFile() {
            if (!this.imageSrc) return;

            const iframe = this.$refs.pictureIframe;

            // Записываем контент в iframe
            const doc = iframe.contentWindow.document;
            doc.open();
            doc.write(`
                <html>
                <head>
                    <title>Печать изображения</title>
                    <style>
                    body, html { margin: 0; padding: 0; text-align: center; }
                    img { max-width: 100%; max-height: 100vh; }
                    </style>
                </head>
                <body>
                    <img src="${this.imageSrc}" alt="Image for Print"/>
                </body>
                </html>
            `);
            doc.close();

            // Ждем загрузки изображения и вызываем печать
            iframe.onload = () => {
                iframe.contentWindow.focus();
                iframe.contentWindow.print();
                document.body.removeChild(iframe);
            };
        },

        onDownloadFile() {
            this.$emit('downloadFile', this.fileName);
        },

        async renderDocument(blob) {
            this.imageSrc = URL.createObjectURL(blob);
            this.getImgHeight()
        },

        getImgHeight() {
            const img = new Image();
            img.src = this.imageSrc;
            
            img.onload = () => {
                const imgHeight = img.height;
                const imgWidth = img.width;
                this.setContainerSize(imgHeight, imgWidth)
            };
        },

        setContainerSize(imgHeight, imgWidth) {
            const isPictPortrait = imgHeight > imgWidth;
            if (isPictPortrait) {
                this.imageHeight = this.$refs.pictureViewer ? this.$refs.pictureViewer.offsetHeight * 0.95 : imgHeight;
                this.imageHeightBase = this.imageHeight;
            } else {
                this.imageWidth = this.$refs.pictureViewer ? this.$refs.pictureViewer.offsetWidth * 0.85 : imgWidth;
                this.imageWidthBase = this.imageWidth;
            }
        }
        
    },
}
</script>

<style scoped>
.doc-prev_container {
    position: fixed;
    top: 56px;
    width: 100%;
    padding-right: 112px;
}

#picture-viewer {
    text-align: center;
    padding: 20px;
    overflow-y: auto;
    overflow-x: auto;
    height: calc(100vh - 106px);
}

.right-tab_bts-container {
    position: fixed;
    right: 100px;
    display: flex;
    flex-direction: column;
}

.right-tab_bts-container button{
    height: 50px;
    width: 50px;
    align-self: center;
    margin: 3px;
    opacity: 0.3;
}

.right-tab_bts-container button:hover {
    opacity: 1;
}

.doc-prev_file-name {
    padding: 5px 100px;
    width: 100%;
    text-align: center;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.document-preview_spinner-container{
    margin-top: 10%;
    display: flex;
    justify-content: center;
}
.document-preview_spinner {
    margin: 10px;
}
</style>
