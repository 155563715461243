<template>
    <div>
        <document-preview-pdf
            ref="docPrevPdf"
            v-if="fileType === 'pdf'"
            :isFileLoading="isFileLoading"
            :spinerVariants="spinerVariants"
            :fileName="fileName"
            :fileNameCutted="fileNameCutted"
            :isError="isError"
            :isFileDownloading="isFileDownloading"
            @downloadFile="downloadFile"
        >
        </document-preview-pdf>
        <document-preview-picture
            ref="docPrevPicture"
            v-if="fileType === 'picture'"
            :isFileLoading="isFileLoading"
            :spinerVariants="spinerVariants"
            :fileName="fileName"
            :fileNameCutted="fileNameCutted"
            :isError="isError"
            :isFileDownloading="isFileDownloading"
            @downloadFile="downloadFile"
        >
        </document-preview-picture>

        <div v-if="isError">
            <b-card class="text-center document-preview_error-card">
                <b-button 
                    variant="warning"
                    :disabled="isFileDownloading"
                    @click="onErrorBtnClicked"
                >
                {{ "Загрузить файл на компьютер" }}</b-button>
            </b-card>
        </div>
    </div>
</template>


<script>
import DocumentPreviewPdf from './document-preview-pdf.vue';
import DocumentPreviewPicture from './document-preview-picture.vue';
import { Ax } from '@/utils';

export default {
    name: 'document-preview',
    components: { 
        DocumentPreviewPdf,
        DocumentPreviewPicture,
    },
    mounted() {
        this.prepareData();
    },
    data() {
        return {
            header: null,
            spinerVariants: ['danger', 'success', 'primary'],
            isFileLoading: false,
            isError: false,
            isFileDownloading: false
        }
    },
    methods: {
        async prepareData() {
            this.decodParams();
            this.getFileInBinary();
        },

        decodParams() {
            const query = this.$route.query.data;

            if (query) {
                const decodedParams = JSON.parse(decodeURIComponent(query));
                this.header = decodedParams;
            }
        },

        async getFileInBinary() {
            this.isFileLoading = true;
            this.header.fileNameNoExt = this.fileNameNoExt;
            this.header.fileExtention = this.fileExtention;
            this.header.fileType = this.fileType;
            try {
                const response = await fetch("/api-py/download-file-for-preview/", {
                    method: "POST",
                    body: JSON.stringify(this.header),
                    headers: {
                        "Content-Type": "application/json",
                    }
                })

                if (response.ok) {
                    if (response.headers.get("Content-Type") === 'application/json') {
                        const result = await response.json();
                        if ('error' in result && 'message' in result) {
                            this.makeToast('danger', 'Ошибка получения файла', `${result.error} - ${result.message}`);
                        }
                    } else {
                        const blob = await response.blob()
                        this.renderDoc(blob);
                    }
                } else {
                    this.isError = true;
                    this.makeToast('danger', 'Ошибка получения файла', `${response.status} - ${response.statusText}`);
                }
            } catch (error) {
                this.isError = true;
                this.makeToast('danger', 'Ошибка получения файла', error.toString());
            } finally {
                this.isFileLoading = false;
            }
        },

        renderDoc(response) {
            switch (this.fileType) {
                case 'pdf':
                    this.$refs.docPrevPdf.renderDocument(response);
                    break;
                case 'picture':
                    this.$refs.docPrevPicture.renderDocument(response);
                    break;
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        },

        onErrorBtnClicked() {
            this.downloadFile(this.fileName, false)
        },

        downloadFile(filename) {
            this.isFileDownloading = true;
            let url = '/api-py/download-file/';
            if ('isPaidTab' in this.header && this.header.isPaidTab === true) {
                url = '/api-py/download-file-paid/';
            }
            Ax(
                {   
                    url: url + filename + '/' + JSON.stringify(this.header),
                    method: 'POST',
                    data: null,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);// or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.isFileDownloading = false;
                },
                (error) => {
                    this.isFileDownloading = false;
                    this.makeToast('danger', this.getLocText('download_err'), error.toString());
                }
            );
        }, // скачать файл
    },

    computed: {
        fileName() {
            if (this.header && this.header.file_name) {
                return this.header.file_name;
            }
            return '';
        },
        fileNameCutted() {
            const fileName = this.fileName;
            const fileNameNoExt = this.fileNameNoExt;
            const nameLengthLimit = 80;
            if (fileNameNoExt.length > nameLengthLimit) {
                return fileNameNoExt.slice(0, nameLengthLimit) + '... ' + this.fileExtention;
            } else {
                return fileName;
            }
        },
        fileNameNoExt() {
            const lastIndex = this.fileName.lastIndexOf('.');
            if (lastIndex === -1) {
                return ''; 
            } else {
                return this.fileName.substring(0, lastIndex);
            }
        },
        fileExtention() {
            const lastIndex = this.fileName.lastIndexOf('.');
            if (lastIndex === -1) {
                return ''; 
            } else {
                return this.fileName.substring(lastIndex);
            }
        },
        fileType() {
            const pictureFormats = ['.jpg', '.jpeg', '.bmp', '.png', '.gif', '.svg', '.webp', '.ico', '.avif'];
            if (pictureFormats.includes(this.fileExtention.toLowerCase())) return 'picture';
            
            return 'pdf';
        }
    }
}

</script>

<style scoped>
.document-preview_error-card {
    width: 400px;
    margin: auto;
    height: 200px;
}
.document-preview_error-card button {
    margin: 15% auto;
}
</style>